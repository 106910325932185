<vwui-root>
    <a11y-skip-link *ngIf="menuVisible">{{ 'A11Y.go_to_content' | translate }}</a11y-skip-link>
    <a11y-skip-link *ngIf="!menuVisible" [skipToElement]="'.skip-element'">{{ 'A11Y.go_to_content' | translate }}</a11y-skip-link>

    <vwui-nav *ngIf="menuVisible">
        <vwui-logo slot="logo" [logoSrc]="environment.logo"></vwui-logo>

        <app-menu type="projects"></app-menu>
        <app-menu type="admin"></app-menu>

        <div slot="nav-footer" *ngIf="userHasLogin">
            <div class="nav-logout-wrapper">
                <vwui-nav-item
                    iconName="vwui-user"
                    (click)="logout()"
                    href="/logout"
                    tabindex="-1"
                >
                    {{ 'BUTTON.logout' | translate }}
                    <vwui-icon class="small" iconName="vwui-logout"></vwui-icon>
                </vwui-nav-item>
            </div>
            <div class="nav-version-wrapper">
                <app-version></app-version>
            </div>
        </div>
    </vwui-nav>
    <vwui-main>
        <router-outlet></router-outlet>
    </vwui-main>
</vwui-root>
